import fetch from '../../../apis/request'

export default class Apis {
  // 分页查询所有检查记录
  static findAllList(formData) {
    return fetch.get('/pc/securityCheck/findAll', {
      params: formData
    })
  }
  // 日常检查表
  static findAllCheckRecord(formData) {
    return fetch.get('/pc/hangingBasket/checkRecord', {
      params: formData
    })
  }
  // 导出台班(按月）
  static exportTaiZhanglist(formData) {
    return fetch.get('/pc/hangingBasket/exportCheckRecord', {
      params: formData
    })
  }

  // 获取台班(按月）
  static getTaiBanlistByMonth(formData) {
    return fetch.get('/pc/hangingBasket/listByMonth', {
      params: formData
    })
  }

  // 导出台班(按月）
  static exportTaiBanlistByMonth(formData) {
    return fetch.get('/pc/hangingBasket/exportByMonth', {
      params: formData
    })
  }

  // 获取台班(按年）
  static getTaiBanlistByYear(formData) {
    return fetch.get('/pc/hangingBasket/listByYear', {
      params: formData
    })
  }

  // 导出台班(按年）
  static exportTaiBanlistByYear(formData) {
    return fetch.get('/pc/hangingBasket/exportByYear', {
      params: formData
    })
  }
  // 获得详情
  static getDetails(data) {
    return fetch.get(`/pc/securityCheck/getDetails?id=${data}`)
  }

  // 根据年份查询吊篮台班汇总台账
  static totalByYear(formData) {
    return fetch.get('/pc/hangingBasket/totalByYear', {
      params: formData
    })
  }

  // 吊篮巡检汇总台账
  static checkSummary(formData) {
    return fetch.get('/pc/check-summary/count', {
      params: formData
    })
  }

  // 查询使用记录
  static useRecord(formData) {
    return fetch.get('/pc/use-record/page', {
      params: formData
    })
  }

  // 高处作业吊篮使用统计(按月）
  static useRecordByMonth(formData) {
    return fetch.get('/pc/use-record/listByMonth', {
      params: formData
    })
  }

  // 高处作业吊篮使用统计(按年）
  static useRecordByYear(formData) {
    return fetch.get('/pc/use-record/listByYear', {
      params: formData
    })
  }

  // 吊篮使用率统计（天）
  static listRateByMonth(formData) {
    return fetch.get('/pc/use-record/listRateByMonth', {
      params: formData
    })
  }

  // 吊篮使用率统计（月）
  static listRateByYear(formData) {
    return fetch.get('/pc/use-record/listRateByYear', {
      params: formData
    })
  }

  // 查询大型机械使用记录
  static largeMachineRecord(formData) {
    return fetch.get('/pc/large-machine-record/page', {
      params: formData
    })
  }

  // 大型设备使用统计(按月）
  static largeMachineRecordByMonth(formData) {
    return fetch.get('/pc/large-machine-record/listByMonth', {
      params: formData
    })
  }

  // 大型设备使用统计(按年）
  static largeMachineRecordByYear(formData) {
    return fetch.get('/pc/large-machine-record/listByYear', {
      params: formData
    })
  }

  // 根据年份查询大型设备台班汇总台账
  static largeMachineRecordTotalByYear(formData) {
    return fetch.get('/pc/large-machine-record/totalByYear', {
      params: formData
    })
  }

  // 吊篮综合台班查询
  static hangingBasketTotal(formData) {
    return fetch.get('/pc/hangingBasket/total', {
      params: formData
    })
  }

  // 吊篮在场台班(按年）
  static getZaiChanglistByYear(formData) {
    return fetch.get('/pc/totalMachine/listByYear', {
      params: formData
    })
  }

  // 吊篮在场台班(按月）
  static getZaiChanglistByMonth(formData) {
    return fetch.get('/pc/totalMachine/listByMonth', {
      params: formData
    })
  }
}
